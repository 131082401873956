import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AdminComponent } from './admin/admin.component';
import { HomeComponent } from './components/home/home.component';

const routes: Routes = [
  {
    path: 'home',
    component: HomeComponent,
  }, {
    path: 'admin',
    component: AdminComponent,
    loadChildren: () =>
      import("./admin/admin.module").then(
        (m) => m.AdminModule
      ),
  },
  {
    path: "landing",
    loadChildren: () =>
      import("./pages/landing/landing.module").then(
        (m) => m.LandingModule
      ),
  },
  {
    path: "staking",
    loadChildren: () =>
      import("./pages/staking/staking.module").then(
        (m) => m.StakingModule
      ),
  },
  {
    path: "",
    redirectTo: "landing",
    pathMatch: "full",
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }

