import { Component, OnInit } from '@angular/core';
import { AddTokenAMetamaskService } from 'src/app/services/add-metamask.service';
import { ContractService } from 'src/app/services/contract.service';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {

  accountStatus: any;

  constructor(
    public metamaskService: AddTokenAMetamaskService,
    public contractService: ContractService) { }

  ngOnInit(): void {

    this.contractService.dataStatusSource.subscribe(res => {
      this.accountStatus = res;
    })
  }


  addToken() {
    this.metamaskService.addToken()
  }

  addEthereumChain() {
    this.metamaskService.addEthereumChain()
  }


  getAddress(address: string) {
    if (!address) { return }
    return address.substring(0, 6) + '...' + address.substring(address.length - 4, address.length)
  }

  connectAccount() {
    console.log("connectAccount")
    this.contractService.connectAccount();
  }

  logout() {
    this.contractService.logout();
  }

}
