import { environment } from './../../environments/environment';
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import Web3 from 'web3';
import * as WalletConnectProvider from '@walletconnect/web3-provider'
import * as Web3Modal from "web3modal"
import { AbiService } from './abi.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { AddTokenAMetamaskService } from './add-metamask.service';
import { Sweetalert2Service } from './sweetalert2.service';
import { fromWei, toWei } from '../helpers/utils';


@Injectable({
  providedIn: 'root'
})
export class ContractService {

  private web3js: any;
  private provider: any;
  public accounts: any;
  _web3Modal: any
  uToken: any
  uTokenStaked: any;
  _atracionesDisponibles: any

  public spinnerConecction = new Subject<any>();
  spinnerConecction$ = this.spinnerConecction.asObservable();

  public accountStatusSource = new Subject<any>();
  accountStatus$ = this.accountStatusSource.asObservable();

  public dataStatusSource = new Subject<any>();
  dataStatus$ = this.dataStatusSource.asObservable();

  public setAccountStakes = new Subject<any>();
  accountStakes$ = this.setAccountStakes.asObservable();



  constructor(
    private spinner: NgxSpinnerService,
    public sweetalert2Service: Sweetalert2Service,
    public metamaskService: AddTokenAMetamaskService,
    public abiService: AbiService) {

    const providerOptions = {
      walletconnect: {
        package: WalletConnectProvider.default,
        chainId: environment.chain.chainId,
        options: {
          chainId: environment.chain.chainId,
          infuraId: environment.infuraId, // required,
          rpc: {
            137: "https://polygon-mainnet.infura.io/v3/" + environment.infuraId,
            80001: "https://polygon-mumbai.infura.io/v3/" + environment.infuraId,
          },
        },
        display: {
          description: "Scan with a wallet to connect",
        },
      },
    };

    this._web3Modal = new Web3Modal.default({
      network: "mainnet", // optional
      cacheProvider: true, // optional
      providerOptions, // required
      theme: {
        background: "#e5e61d",
        main: "#000000",
        secondary: "rgb(136, 136, 136)",
        border: "rgba(195, 195, 195, 0.14)",
        hover: "rgb(16, 26, 32)"
      }
    });
  }





  async connectAccount() {
    this._web3Modal.clearCachedProvider();

    this.provider = await this._web3Modal.connect(); // set provider
    this.web3js = new Web3(this.provider); // create web3 instance
    this.accounts = await this.web3js.eth.getAccounts();

    this.eventsAll()

    this.reInitializating()

  }

  async reInitializating() {
    console.log("reinitializando")
    /** spinner starts on init */

    // --- temporarily re-initializating these for the effect file 
    this.provider = await this._web3Modal.connect(); // set provider
    this.web3js = new Web3(this.provider); // create web3 instance
    this.accounts = await this.web3js.eth.getAccounts();

    let token_abi: any = await this.abiService.getABI(1);
    let token_abiStaked: any = await this.abiService.getABI(2);

    this.uToken = new this.web3js.eth.Contract(token_abi, environment.contractAddress);
    this.uTokenStaked = new this.web3js.eth.Contract(token_abiStaked, environment.contractAddress2);


    await this.getData()
  }




  getAbiContract(token_abi, token_address) {
    let uToken: any = new this.web3js.eth.Contract(token_abi, token_address);
    return uToken;
  }

  getApproveTokenAddress(addresstoken: string, amount: string, decimals) {
    return new Promise((resolve, reject) => {
      try {

        // cargamos la abi de contracto secundarios con el metodo approve
        let utoken = this.getAbiContract([{
          "inputs": [
            {
              "internalType": "address",
              "name": "tokenOwner",
              "type": "address"
            },
            {
              "internalType": "uint256",
              "name": "amount",
              "type": "uint256"
            }
          ],
          "name": "approve",
          "outputs": [
            {
              "internalType": "bool",
              "name": "",
              "type": "bool"
            }
          ],
          "stateMutability": "nonpayable",
          "type": "function"
        }], addresstoken)

        let account = this.accounts[0]
        let tokenTotal = toWei(amount, decimals)

        // hacemmos la consulta
        utoken
          .methods
          .approve(environment.contractAddress, tokenTotal)
          .estimateGas({ from: account })
          .then(async (gas) => {
            console.log("gas", gas);
            // We now have the gas amount, we can now send the transaction
            await
              utoken
                .methods
                .approve(environment.contractAddress, tokenTotal)
                .send({
                  from: account,
                  gas: gas
                })

            await this.reInitializating()
            this.sweetalert2Service.showSuccess('approve exitoso', 2)
            resolve(true)
          }).catch((err) => {
            this.spinner.hide();
            this.sweetalert2Service.showError(err.message, 1)
            resolve(false)
          });
      } catch (err) {
        console.log("error", err)
        this.sweetalert2Service.showError("Error", 2)
        resolve(false)
      }

    })



  }


  async getData() {
    return new Promise(async (resolve, reject) => {
      try {

        this.spinnerConecction.next(true)

        // console.log("getData")
        let data: any = {}

        data.contractAddress = environment.contractAddress;
        data.myEther = await this.getBalanceEth(this.accounts[0]);
        data.accounts = this.accounts[0]
        data.getLatestPriceMATICUSD = await this._method("getLatestPriceMATICUSD", 'methods')
        data.getLatestPriceWBTCUSD = await this._method("getLatestPriceWBTCUSD", 'methods')
        data.getLatestPriceWETHUSD = await this._method("getLatestPriceWETHUSD", 'methods')
        data.getLatestPriceCOPUSD = await this._method("getLatestPriceCOPUSD", 'methods')
        data.getLatestPriceUSDCOP = await this._method("getLatestPriceUSDCOP", 'methods')
        data.balanceOfdly = await this._method("balanceOfdly", 'methods', this.accounts[0])
        data.balanceOfUSDT = await this._method("balanceOfUSDT", 'methods', this.accounts[0])
        data.balanceOfUSDC = await this._method("balanceOfUSDC", 'methods', this.accounts[0])
        data.balanceOfBTC = await this._method("balanceOfBTC", 'methods', this.accounts[0])
        data.balanceOfETH = await this._method("balanceOfETH", 'methods', this.accounts[0])
        data.owner = await this._method("owner", 'methods')


        if (data.owner == this.accounts[0] ) {
          data.myContractEther = await this.getBalanceEth(environment.contractAddress);
          data.balanceOfdlyAdmin = await this._method("balanceOfdly", 'methods', environment.contractAddress)
          data.balanceOfUSDTAdmin = await this._method("balanceOfUSDT", 'methods', environment.contractAddress)
          data.balanceOfUSDCAdmin = await this._method("balanceOfUSDC", 'methods', environment.contractAddress)
          data.balanceOfBTCAdmin = await this._method("balanceOfBTC", 'methods', environment.contractAddress)
          data.balanceOfETHAdmin = await this._method("balanceOfETH", 'methods', environment.contractAddress)
        

          // oracle
          data.trmCopUsdManual = await this._method("trmCopUsdManual", 'methods')
          data.trmUsdCopManual = await this._method("trmUsdCopManual", 'methods')
          data.trmMaticUsdManual = await this._method("trmMaticUsdManual", 'methods')
          data.trmWbtcUsdManual = await this._method("trmWbtcUsdManual", 'methods')
          data.trmWethUsdManual = await this._method("trmWethUsdManual", 'methods')

          data.valueTrmCopUsdManual = await this._method("valueTrmCopUsdManual", 'methods')
          data.valueTrmUsdCopManual = await this._method("valueTrmUsdCopManual", 'methods')
          data.valueTrmMaticUsdManual = await this._method("valueTrmMaticUsdManual", 'methods')
          data.valueTrmWbtcUsdManual = await this._method("valueTrmWbtcUsdManual", 'methods')
          data.valueTrmWethUsdManual = await this._method("valueTrmWethUsdManual", 'methods')

          // limit buy/sell 
          data.sellLimit = await this._method("sellLimit", 'methods')
          data.buyLimit = await this._method("buyLimit", 'methods')
        }

        data.lastBuy = await this._method("getLastBuy", 'methods', this.accounts[0])
        data.lastSell = await this._method("getLastSell", 'methods', this.accounts[0])

        console.log("getData", data)
        this.dataStatusSource.next(data)
        this.spinnerConecction.next(false)
        this.spinner.hide();
        resolve('ok')
      } catch (err) {
        this.dataStatusSource.next(null)
        this.spinner.hide();
        reject(err)
      }

    })
  }

  // method common
  async _method(method: string, type: string, params?: any) {
    let result;
    if (method == 'allowance') {
      result = await this.uToken[type][method](params, environment.contractAddress).call()
        .catch((error) => {
          console.log("error", error)
        })
    }
    else if (params) {
      result = await this.uToken[type][method](params).call()
        .catch((error) => {
          console.log("error", error)
        })
    } else {
      result = await this.uToken[type][method]().call()
        .catch((error) => {
          console.log("error", error)
        })
    }

    return result;
  }

  checkNetwork() {
    this.web3js.eth.net.getId()
      .then(id => {
        if (id != environment.chain.chainId) {
          this.sweetalert2Service.showWarning('Please switch to the Polygon Network Smart network', 2);
          this.metamaskService.addEthereumChain()
        }
      })
  }

  getPastLogs() {
    console.log("getPastLogs")
    this.web3js.eth.getPastLogs({ fromBlock: '0x0', address: environment.contractAddress })
      .then(res => {
        console.log("getPastLogs", res)
        res.forEach(rec => {
          console.log(rec.blockNumber, rec.transactionHash, rec.topics);
        });
      }).catch(err => console.log("getPastLogs failed", err));
  }

  eventsAll() {
    // Subscribe to accounts change
    this.provider.on("accountsChanged", (accounts: string[]) => {
      console.warn("accountsChanged", accounts);
      this.accountStatusSource.next(accounts)
      window.location.reload()
    });

    // Subscribe to chainId change
    this.provider.on("chainChanged", (chainId: number) => {
      console.log("chainChanged", chainId);
      this.reInitializating()
    });

    // Subscribe to provider connection
    this.provider.on("connect", (info: { chainId: number }) => {
      this.reInitializating()
    });

    // Subscribe to provider disconnection
    this.provider.on("disconnect", (error: { code: number; message: string }) => {
      console.log("disconnect", error);
      window.location.reload()
    });
  }


  _methods() {
    return this.uToken.methods
  }


  getDataContract() {
    let data: any = localStorage.getItem('_data_contract');
    if (data == "") { return "" }
    return JSON.parse(data)
  }


  /**
  * @notice  verify that the address is valid
  */
  getBalanceEth(account: string) {
    return new Promise((resolve, reject) => {
      this.web3js.eth.getBalance(account, (err, res) => {
        if (err) {
          console.log(err)
          reject(err)
        } else {
          resolve(res)
        }
      })
    })
  }


  async logout() {
    await this._web3Modal.clearCachedProvider();
    this.provider = null

    localStorage.setItem('_data_accounts', "")
    localStorage.setItem('_data_contract', "")
    this.accountStatusSource.next(null)
    this.dataStatusSource.next(null)
    window.location.reload()
  }

}
