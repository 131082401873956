// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  API_URL: '#',
  urlWeb: '#',
  configUrlAbi: "/assets/abi/VendorDLY.json",  // Vendor DLY
  configUrlAbi2: "/assets/abi/DLYStaked.json", // Staked DLY
  contractAddress: "0xc50163B6F0d5A939970C9C5F4C6E9BCb0CFfD557", // Vendor DLY
  contractAddress2: "0x66611b747C0aFDc6c42b184811b0D129ceCbc0C6", // Staked DLY
  externalContractAddress: {
    matic: {
      decimals: 18,
      oracle: 8,
    },
    dly: {
      address: "0x403A5aB3ff32220CbCd028e158fF8C059D04C0f1",
      decimals: 18,
      oracle: 18,
      symbol: "DLY",
      image: "https://firebasestorage.googleapis.com/v0/b/dly-dapp.appspot.com/o/small_logo.png?alt=media&token=3d15f795-13c0-47b7-a704-0a24b21ef88b"
    },
    usdt: {
      address: "0xc2132D05D31c914a87C6611C10748AEb04B58e8F",
      decimals: 6,
      oracle: 18,
    },
    usdc: {
      address: "0x2791Bca1f2de4661ED88A30C99A7a9449Aa84174",
      decimals: 6,
      oracle: 18,
    },
    weth: {
      address: "0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619",
      decimals: 18,
      oracle: 8,
    },
    wbtc: {
      address: "0x1BFD67037B42Cf73acF2047067bd4F2C47D9BfD6",
      decimals: 8,
      oracle: 8,

    },
  },
  infuraId: "356256bc3fcf42de88d2bc2e129ea5d9",
  logo_small: "https://firebasestorage.googleapis.com/v0/b/dly-dapp.appspot.com/o/small_logo.png?alt=media&token=3d15f795-13c0-47b7-a704-0a24b21ef88b",
  chain: {
    chainId: "137",
    chainName: "Polygon Mainnet",
    rpcUrls: ["https://rpc-mainnet.maticvigil.com/"],
    nativeCurrency: {
      name: "Polygon Mainnet",
      symbol: "MATIC",
      decimals: "18",
    },
    blockExplorerUrls: ["https://explorer.matic.network/"]
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
