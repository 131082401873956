import { Component, OnInit } from '@angular/core';
import { AddTokenAMetamaskService } from './services/add-metamask.service';
import { ContractService } from './services/contract.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  title = 'ethdapp';
  constructor(
    public contractService: ContractService) {

  }
  async ngOnInit(): Promise<void> {
    await this.contractService.connectAccount()
    await this.contractService.reInitializating()

    // setTimeout(() => {
    //   this.contractService.withdraw()
    // }, 5000);

    this.contractService.checkNetwork()
  }




}
