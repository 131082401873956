import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import Swal from 'sweetalert2';


@Injectable({
  providedIn: 'root'
})
export class AddTokenAMetamaskService {

  token: any
  constructor() { }

  async addToken() {
    console.log("addToken")
    try {

      // wasAdded is a boolean. Like any RPC method, an error may be thrown.
      const wasAdded = await window.ethereum.request({
        method: 'wallet_watchAsset',
        params: {
          type: 'ERC20', // Initially only supports ERC20, but eventually more!
          options: {
            address: environment.externalContractAddress.dly.address, // The address that the token is at.
            symbol: environment.externalContractAddress.dly.symbol, // A ticker symbol or shorthand, up to 5 chars.
            decimals: environment.externalContractAddress.dly.decimals, // The number of decimals in the token
            image: environment.externalContractAddress.dly.image, // A string url of the token logo
          },
        },
      });

      if (wasAdded) {
        console.log('Thanks!');
        Swal.fire('DLY', 'Token added successfully', 'success');
      } else {
        console.log('Your loss!');
        Swal.fire('DLY', 'Token already added', 'warning');
      }
    } catch (error) {
      alert(JSON.stringify(error));
      console.log(error);
    }
  }

  async addEthereumChain() {
    console.log("addEthereumChain")
    try {
      await window.ethereum.request({
        method: 'wallet_switchEthereumChain',
        params: [{ chainId: '0x89' }],
      });
    } catch (error: any) {
      console.log("error", error)
      if (error.code === 4902) {
        try {
          const wasAdded = await window.ethereum.request({
            method: 'wallet_addEthereumChain',
            params: [
              {
                chainId: '0x89',
                chainName: 'Polygon Mainnet',
                nativeCurrency: {
                  name: 'MATIC',
                  symbol: 'MATIC',
                  decimals: 18,
                },
                rpcUrls: ['https://rpc-mainnet.maticvigil.com/'],
                blockExplorerUrls: ['https://polygonscan.com/'],
              },
            ],
          });

          if (wasAdded) {
            Swal.fire('DLY', environment.chain.chainName + ' ' + 'added successfully', 'success');
            window.location.reload();
          } else {
            console.log('Your loss!');
            Swal.fire('DLY', environment.chain.chainName + ' ' + ' already added', 'warning');

          }
        } catch (error: any) {
          console.log("error", error)
          alert(JSON.stringify(error));
        }
      }
    }
  }
}
