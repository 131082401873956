import { Injectable } from '@angular/core';
import Swal from 'sweetalert2';

@Injectable({
  providedIn: 'root'
})
export class Sweetalert2Service {

  constructor() { }

  showError(message, type) {

    try {
      let mess = type == 1 ? JSON.parse(message.substring(message.search("{"), message.length)).message : message
      Swal.fire('DLY', mess, 'error');
    } catch (error) {
      console.log("error", error)
      Swal.fire('DLY', 'error', 'error');
    }


  }

  showQuestion(message, type) {
    try {
      let mess = type == 1 ? JSON.parse(message.substring(message.search("{"), message.length)).message : message
      Swal.fire('DLY', mess, 'error');
      return
    } catch (error) {
      console.log("error", error)
      Swal.fire('DLY', 'error', 'error');
    }



  }

  showWarning(message, type) {
    try {
      let mess = type == 1 ? JSON.parse(message.substring(message.search("{"), message.length)).message : message
      Swal.fire('DLY', mess, 'warning');
      return
    } catch (error) {
      console.log("error", error)
      Swal.fire('DLY', 'error', 'error');

    }

  }

  showSuccess(message, type, transactionHash?) {
    try {
      let mess;

      if (type == 1) {
        mess = JSON.parse(message.substring(message.search("{"), message.length)).message
      } else if (type == 2) {
        mess = message
      } else if (type == 3) {
        Swal.fire({
          icon: 'success',
          title: 'Successful Transactions',
          footer: '<p><a target="_blank" href="https://polygonscan.com/tx/ ' + transactionHash + '"> Transaction Hash</a></p>'
        })
        return
      }
      Swal.fire('DLY', mess, 'success');
      return
    } catch (error) {
      console.log("error", error)
      Swal.fire('DLY', 'error', 'error');
    }

  }
}
