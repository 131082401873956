<div class="row mb-5">
    <div class="col-12 col-sm-12 col-md-8 col-lg">
        <div class="row mt-4">

            <div (click)="addEthereumChain()" class="col">
                <div class="card card_token btn_token">
                    <div class="card-body">
                        <div class="row">
                            <div class="col-12">
                                <div class="btn_meta">
                                    <div class="label text-center">Agregar Red</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div (click)="addToken()" class="col">
                <div class="card card_token btn_token">
                    <div class="card-body">
                        <div class="row">
                            <div class="btn_meta">
                                <div class="label text-center">Agregar Token</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>