<nav class="navbar navbar-expand-lg navbar_token posre">
    <div class="container-fluid">
        <a class="navbar-brand navbar_token_brand" href="#">
            <img src="/assets/img/logo_m.svg" alt="">
        </a>
        <button class="navbar-toggler navbar_toggler_token" type="button" data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false"
            aria-label="Toggle navigation">
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-list"
                viewBox="0 0 16 16">
                <path fill-rule="evenodd"
                    d="M2.5 12a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5z" />
            </svg>
        </button>
        <div class="collapse navbar-collapse" id="navbarSupportedContent">
            <ul class="navbar-nav me-auto mb-2 mb-lg-0">
                <li *ngIf="accountStatus" class="nav-item d-flex align-items-center pe-2 ps-2">
                    <div class="d-flex text-blanco align-items-center">
                        <div class="pe-2">
                            Account:
                        </div>
                        <div>{{getAddress(accountStatus.accounts) }}</div>
                    </div>
                </li>
                <li *ngIf="accountStatus" class="nav-item d-flex align-items-center pe-2 ps-2">
                    <div class="d-flex text-blanco align-items-center">
                        <div class="pe-2">
                            Balance:
                        </div>
                        <div> {{accountStatus?.myEther }}
                            {{accountStatus?.red }}</div>
                    </div>
                </li>
                <li *ngIf="accountStatus" class="nav-item d-flex align-items-center pe-2 ps-2">
                    <div class="d-flex text-blanco align-items-center">
                        <div class="pe-2">
                            Balance:
                        </div>
                        <div>
                            {{accountStatus?.balanceOf | web3Utils:'fromWei':'18'}}
                            {{accountStatus?.symbol }}
                        </div>
                    </div>
                </li>
                <li class="nav-item">
                    <button (click)="addToken()" class="btn btn_token" *ngIf="accountStatus == null"
                        (click)="connectAccount()">
                        {{'conectar'|translate|titlecase}}
                    </button>
                    <button (click)="logout()" class="btn btn_token" *ngIf="accountStatus != null" (click)="logout()">
                        {{'Logout'|translate|titlecase}}
                    </button>
                </li>
            </ul>
        </div>
    </div>
</nav>